/**
 * Internal dependencies
 */
import { convertFormDataToCartData } from '@wpsimplepay/cart';
import { Cart } from '@wpsimplepay/pro/frontend/payment-forms/stripe-elements/cart';
import { getPaymentMethod } from '@wpsimplepay/pro/frontend/payment-methods';
import { getPaymentForms } from '@wpsimplepay/pro/frontend/payment-forms';
import { onPaymentFormError, getPaymentFormType } from '@wpsimplepay/core/frontend/payment-forms';

/**
 * Setup Stripe Elements payment form.
 *
 * @param {Event} e simpayBindCoreFormEventsAndTriggers Event.
 * @param {jQuery} spFormElem Form element jQuery object.
 * @param {Object} formData Configured form data.
 */
export function setup( e, spFormElem, formData ) {
	const realFormElem = spFormElem[ 0 ];

	const { enableForm, disableForm } = window.simpayApp;
	const { debugLog } = window.spShared;

	// Don't continue if this form is using Stripe Checkout.
	// Checking again here due to legacy structure/setup.
	if ( 'stripe-checkout' === getPaymentFormType( spFormElem, formData ) ) {
		return;
	}

	// Disable Payment Form during setup.
	disableForm( spFormElem, formData, true );

	try {
		// Convert legacay data in to something usable.
		const {
			items,
			currency,
			taxPercent,
			isNonDecimalCurrency,
		} = convertFormDataToCartData( formData );

		const cart = new Cart( {
			currency,
			taxPercent,
			isNonDecimalCurrency,
		} );

		if ( items.length > 0 ) {
			items.forEach( ( item ) => {
				cart.addLineItem( item );
			} );
		}

		// Attach cart.
		spFormElem.cart = cart;

		// Handle Payment Method change.
		// @todo Setup elsewhere.
		const { paymentMethods: formPaymentMethods } = formData;
		spFormElem.paymentMethod = formPaymentMethods[0].id;

		_.each( realFormElem.querySelectorAll( '.simpay-payment-method-toggle' ), ( paymentMethodToggle ) => {
			paymentMethodToggle.addEventListener( 'click', ( e ) => {
				spFormElem.paymentMethod = e.target.dataset.paymentMethod;
			} );
		} );

		// Setup the form's Payment Methods.
		formPaymentMethods.forEach( ( { id } ) => {
			getPaymentMethod( id ).config = _.findWhere( formPaymentMethods, {
				id,
			} );
			getPaymentMethod( id ).setup( spFormElem, formData );
		} );

		// Reenable form.
		enableForm( spFormElem, formData );

		// Attach submission handler.
		realFormElem.addEventListener( 'submit', ( e ) => {
			e.preventDefault();

			if ( window.simpayGoogleRecaptcha ) {
				const { siteKey } = simpayGoogleRecaptcha;

				// @todo Complete syncronously inside of separate reCAPTCHA script.
				//
				// This is a temporary measure to ensure reCAPTCHA tokens are generated
				// at the time of submission to avoid them being invalidated after 120 seconds.
				grecaptcha.ready( () => {
					grecaptcha.execute( siteKey, {
						action: `simple_pay_form_${ formData.formId }_customer`,
					} )
						.then( ( token ) => {
							spFormElem.append( '<input type="hidden" name="grecaptcha_customer" value="' + token + '" />' );

							grecaptcha.execute( siteKey, {
								action: `simple_pay_form_${ formData.formId }_payment`,
							} )
								.then( ( token ) => {
									spFormElem.append( '<input type="hidden" name="grecaptcha_payment" value="' + token + '" />' );

									// Find and submit the Payment Form.
									getPaymentForms()[ getPaymentFormType( spFormElem, formData ) ]
										.submit( spFormElem, formData );
								} );
						} );
				} );
			} else {
				getPaymentForms()[ getPaymentFormType( spFormElem, formData ) ]
					.submit( spFormElem, formData );
			}
		} );
	} catch ( error ) {
		onPaymentFormError( error, spFormElem, formData );
	}
}
